import * as React from 'react';

import { MicroAppContextProvider, MicroAppContextType } from '../common/contexts/microAppContext';
import ReactAppWrapper from '../common/init/ReactAppWrapper';
import renderMicroApp, { type MicroAppProps } from '../common/init/renderMicroApp';
import StartPage from './StartPage';

const StartApp = (props: MicroAppProps) => {
    return (
        <MicroAppContextProvider microAppType={MicroAppContextType.Start}>
            <ReactAppWrapper initialQueryClientData={props.initialQueryData}>
                <StartPage />
            </ReactAppWrapper>
        </MicroAppContextProvider>
    );
};

renderMicroApp(StartApp);
