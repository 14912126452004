import { type RefObject, useLayoutEffect } from 'react';

import type { SiteSkipLinkType } from './SiteSkipLinks';

export const useSkipLinkTargetRef = (ref: RefObject<HTMLElement>, id: SiteSkipLinkType) => {
    useLayoutEffect(() => {
        ref.current?.setAttribute('tabIndex', '-1');
        ref.current?.setAttribute('style', 'outline: none');
        if (id) {
            ref.current?.setAttribute('id', id);
        }
    }, [ref, id]);
};
