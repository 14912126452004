/* eslint-disable no-console */
import type { QueryKey } from '@tanstack/react-query';
import type { FC } from 'react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import globalInvokes from '../../../globalFunctionInvokes';
import { getInitialQueryDataFromDOM } from './renderMicroApp.utils';

export interface MicroAppProps<T extends object = object> {
    ssrData?: T;
    initialQueryData?: { key: QueryKey; data: unknown }[];
}

const renderMicroApp = <T extends object = object>(microApp: FC<MicroAppProps<T>>) => {
    const container = document.querySelector<HTMLElement>('#reactMicroApp');
    const domProps = container?.dataset?.reactProps;
    const props = domProps ? (JSON.parse(domProps) as T) : undefined;

    const initialQueryDataDom = document.querySelectorAll<HTMLElement>('.initial-query-data');
    const initialQueryData =
        initialQueryDataDom?.length > 0
            ? Array.from(initialQueryDataDom).map(getInitialQueryDataFromDOM).filter(Boolean)
            : undefined;

    globalInvokes();

    const root = createRoot(container!);
    root.render(React.createElement(microApp, { ssrData: props, initialQueryData }));
};

export default renderMicroApp;
