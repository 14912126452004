'use client';

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import { useEffect, useId, useRef } from 'react';
import { VisuallyHidden } from 'react-aria';
import { CSSTransition } from 'react-transition-group';

import { Loader } from '../Loader';
import { useDelayedIsLoading } from '../Loader/Loader.hooks';
import { PacmanLoader } from '../PacmanLoader';
import styles from './OverlayLoader.module.scss';

export type OverlayLoaderProps = {
    isLoading: boolean;
    additionalClasses?: string;
    type?: 'balls' | 'pacman';
    delay?: number;
    size?: Parameters<typeof Loader>[0]['size'];
    loadingText?: string;
    /** Use when you want to block user from tabbing under loader. This will also trap focus inside the loader until its done */
    blocking?: boolean;
};

export const OverlayLoader = (props: React.PropsWithChildren<OverlayLoaderProps>) => {
    const isLoading = useDelayedIsLoading(props.isLoading, props.delay || 0);
    const loaderId = useId();

    const shouldTrapFocus = isLoading && props.blocking;

    const loadingText = props.loadingText || 'Hämtar data';

    const focusRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (shouldTrapFocus) {
            focusRef.current?.focus();
        }
    }, [shouldTrapFocus]);

    return (
        <>
            <CSSTransition
                nodeRef={focusRef}
                in={isLoading}
                timeout={200}
                classNames={{
                    enter: styles.IsVisible,
                    enterDone: styles.IsVisible,
                }}
                unmountOnExit
                appear
            >
                <FocusTrap
                    active={shouldTrapFocus}
                    focusTrapOptions={{
                        fallbackFocus: `[id="${loaderId}"]`,
                        allowOutsideClick: true,
                    }}
                >
                    <div
                        id={loaderId}
                        ref={focusRef}
                        tabIndex={isLoading ? -1 : undefined}
                        className={classnames(styles.LoaderOverlay, props.additionalClasses)}
                        role={isLoading ? 'alert' : undefined}
                    >
                        <VisuallyHidden aria-live="assertive">{loadingText}</VisuallyHidden>
                        {(() => {
                            if (props.type === 'pacman') {
                                return <PacmanLoader isLoading={isLoading} />;
                            }

                            return <Loader isLoading={isLoading} size={props.size} ariaHidden />;
                        })()}
                    </div>
                </FocusTrap>
            </CSSTransition>
        </>
    );
};

// Native omit does not take discriminated unions into account. This one does. Taken from https://github.com/microsoft/TypeScript/issues/54451
type MappedOmit<T, K extends keyof T> = { [P in keyof T as P extends K ? never : P]: T[P] };

export const OverlayPacmanLoader = ({ ...props }: MappedOmit<OverlayLoaderProps, 'type'>) => {
    return <OverlayLoader {...props} type="pacman" />;
};

export const FullOverlayLoaderWrapper = ({
    isLoading,
    children,
}: {
    isLoading: boolean;
    children: React.ReactNode;
}) => {
    return isLoading ? <OverlayLoader isLoading={isLoading} /> : <>{children}</>;
};
