import { Browser } from '../../../utility/browser';
import { productApi } from '../../common/api/products/productApi';
import { DirectProductProvider } from '../../common/flow/product/directProductProvider';
import userFlow from '../../common/flow/userFlow';
import { searchDirectApi } from '../api/searchApi';
import type {
    ApiLoopAutocompleteRequest,
    ApiLoopCurrentOffersRequest,
    ApiLoopGlobalSearchRequest,
    ApiLoopRangeFacet,
    ApiLoopResultOptions,
    ApiLoopSearchByAttributeRequest,
    ApiLoopSearchRequest,
} from '../api/searchRequests';
import type {
    BaseSearchRequest,
    GlobalSearchRequest,
    GlobalSearchResults,
    LoopNoQueryProductResults,
    LoopSearchProductResults,
    PointshopSearchAndRequest,
    PointshopSearchRequest,
    ProductOfferSearchRequest,
    ProductSearchRequest,
    RecipeSearchRequest,
    SearchArticleResults,
    SearchByAttributeRequest,
    SearchPointshop,
    SearchRecipeResult,
    SearchRequest,
    SearchRequestAlias,
    SearchStoreResults,
} from '../models/models';
import { SearchFacetType } from '../models/models';
import { mapAndFilter, mapAndOrFilter, mapFacets, mapSortOrder } from './mapSearchRequest';
import {
    mapLoopArticleSearchResponse,
    mapLoopAutocompleteResponse,
    mapLoopGlobalSearchResponse,
    mapLoopProductNoQuerySearchResponse,
    mapLoopProductSearchResponse,
    mapLoopSearchPointshop,
    mapLoopSearchRecipe,
    mapLoopStoreSearchResponse,
} from './mapSearchResponse';

const searchRecipesLoop = async ({
    searchRequest,
}: {
    searchRequest: RecipeSearchRequest;
}): Promise<SearchRecipeResult> => {
    let loopSearchRequest: ApiLoopSearchRequest = {
        query: searchRequest.searchQuery,
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            sortBy: searchRequest.sortBy,
            facets: mapFacets(searchRequest.facets),
        },
    };

    // if sortby is null, dont send it.
    if (searchRequest.sortBy === null) {
        loopSearchRequest = {
            query: searchRequest.searchQuery,
            resultsOptions: {
                skip: searchRequest.page * searchRequest.resultsPerPage,
                take: searchRequest.resultsPerPage,
                facets: mapFacets(searchRequest.facets),
            },
        };
    }

    const response = await searchDirectApi.searchRecipes({ loopSearchRequest });

    return {
        count: response.results.count,
        items: response.results.items.map(mapLoopSearchRecipe),
        facets: response.results.facets,
        queryUsed: response.queryUsed,
    };
};

const searchProductsLoop = async (
    searchRequest: ProductSearchRequest,
    abortSignal: AbortSignal | undefined,
    searchService: SearchService | null,
    quickSearch: boolean, // used by loop to indicate if the search was used when you type, they need it for something....
): Promise<LoopSearchProductResults> => {
    const loopSearchRequest: ApiLoopSearchRequest = {
        query: searchRequest.searchTerm,
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            sortBy: mapSortOrder(searchRequest.sortBy),
            facets: mapFacets(searchRequest.facets),
        },
        relatedResultsOptions: {
            skip: 0,
            take: 16,
        },
    };

    const customerGroups = await userFlow.getB2BContextCustomerGroups();
    const device = Browser.platformType();
    const response = await searchDirectApi.searchProducts(
        loopSearchRequest,
        searchRequest.storeId,
        abortSignal,
        searchService,
        customerGroups,
        device,
        quickSearch,
    );
    const mappedResponse = mapLoopProductSearchResponse(response, searchRequest);
    return mappedResponse;
};

const searchProductOffersLoop = async (
    searchRequest: ProductOfferSearchRequest,
    abortSignal?: AbortSignal,
    groupOffers?: boolean,
    overwritePersonalizeCampaigns?: boolean,
) => {
    const loopSearchRequest: ApiLoopCurrentOffersRequest = {
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            sortBy: mapSortOrder(searchRequest.sortBy),
            facets: mapFacets(searchRequest.facets),
        },
        customData: {
            personalizeCampaigns: !!overwritePersonalizeCampaigns,
        },
    };

    const customerGroups = await userFlow.getB2BContextCustomerGroups();
    const response = await productApi.getCurrentProductOffersLoop(
        loopSearchRequest,
        searchRequest.storeId,
        abortSignal,
        customerGroups,
        groupOffers,
    );

    const mappedResponse = mapLoopProductNoQuerySearchResponse(response);
    return mappedResponse;
};

const searchArticlesLoop = async (
    searchRequest: SearchRequest,
    abortSignal: AbortSignal,
): Promise<SearchArticleResults> => {
    const loopSearchRequest: ApiLoopSearchRequest = {
        query: searchRequest.searchTerm,
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            sortBy: mapSortOrder(searchRequest.sortBy),
            facets: mapFacets(searchRequest.facets),
        },
    };

    const response = await searchDirectApi.searchArticles(loopSearchRequest, abortSignal);
    const mappedResponse = mapLoopArticleSearchResponse(response, searchRequest);
    return mappedResponse;
};

const searchStoresLoop = async (
    searchRequest: SearchRequest,
    abortSignal: AbortSignal,
): Promise<SearchStoreResults> => {
    const loopSearchRequest: ApiLoopSearchRequest = {
        query: searchRequest.searchTerm,
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            sortBy: mapSortOrder(searchRequest.sortBy),
            facets: mapFacets(searchRequest.facets),
        },
    };

    const response = await searchDirectApi.searchStores(loopSearchRequest, abortSignal);
    const mappedResponse = mapLoopStoreSearchResponse(response, searchRequest);
    return mappedResponse;
};

const searchLoyaltyProductsById = async (ids: number[]): Promise<SearchPointshop[]> => {
    if (!ids || !ids.length) {
        return [];
    }
    const distinctIds = ids.filter((id, index, result) => id && index === result.indexOf(id));
    const response = await searchDirectApi.getLoyaltyProductsById(distinctIds);

    const mappedResponse = response.results.items
        .filter((x) => x.type === 'PartneroffersEntity' || x.type === 'PointshopEntity')
        .map(mapLoopSearchPointshop);
    return mappedResponse;
};

const searchLoyaltyProducts = async (
    searchRequest: PointshopSearchAndRequest,
): Promise<SearchPointshop[]> => {
    const loopSearchRequest: ApiLoopSearchRequest = {
        query: '',
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            sortBy: mapSortOrder(searchRequest.sortBy),
            facets: mapFacets(searchRequest.facets),
            filter: searchRequest.filter && mapAndFilter(searchRequest.filter),
        },
    };
    const response = await searchDirectApi.searchLoyaltyProducts({ loopSearchRequest });

    const mappedResponse = response.results.items
        .filter((x) => x.type === 'PartneroffersEntity' || x.type === 'PointshopEntity')
        .map(mapLoopSearchPointshop);
    return mappedResponse;
};

const mapResultOptions = (searchRequest: BaseSearchRequest): ApiLoopResultOptions => ({
    skip: searchRequest.page * searchRequest.resultsPerPage,
    take: searchRequest.resultsPerPage,
    sortBy: mapSortOrder(searchRequest.sortBy), // this will probably be different per document type, if we ever use it
    facets: mapFacets(searchRequest.facets), // this will probably be different per document type, if we ever use it
});

const mapResultFilterOptions = (searchRequest: PointshopSearchRequest): ApiLoopResultOptions => ({
    skip: searchRequest.page * searchRequest.resultsPerPage,
    take: searchRequest.resultsPerPage,
    sortBy: mapSortOrder(searchRequest.sortBy), // this will probably be different per document type, if we ever use it
    facets: mapFacets(searchRequest.facets), // this will probably be different per document type, if we ever use it
    filter: searchRequest.filter && mapAndOrFilter(searchRequest.filter),
});

const searchGlobalLoop = async (
    searchRequest: GlobalSearchRequest,
    quickSearch: boolean,
    abortSignal?: AbortSignal,
): Promise<GlobalSearchResults> => {
    const loopGlobalSearchRequest: ApiLoopGlobalSearchRequest = {
        query: searchRequest.searchTerm,
    };

    if (searchRequest.productSearchRequest) {
        loopGlobalSearchRequest.resultsOptions = mapResultOptions(
            searchRequest.productSearchRequest,
        );
    } else {
        // we dont want products data when no productSearchRequest is provided
        loopGlobalSearchRequest.resultsOptions = {
            skip: 0,
            take: 0,
        };
    }

    if (searchRequest.storeSearchRequest) {
        loopGlobalSearchRequest.storesResultsOptions = mapResultOptions(
            searchRequest.storeSearchRequest,
        );
    }

    if (searchRequest.articleSearchRequest) {
        loopGlobalSearchRequest.editorialContentResultsOptions = mapResultOptions(
            searchRequest.articleSearchRequest,
        );
    }

    if (searchRequest.pointshopSearchRequest) {
        loopGlobalSearchRequest.pointshopResultsOptions = mapResultFilterOptions(
            searchRequest.pointshopSearchRequest,
        );
    }

    if (searchRequest.recipeSearchRequest) {
        loopGlobalSearchRequest.recipesResultsOptions = mapResultOptions(
            searchRequest.recipeSearchRequest,
        );
    }

    if (searchRequest.recipeCategoriesSearchRequest) {
        loopGlobalSearchRequest.recipeCategoriesResultsOptions = mapResultOptions(
            searchRequest.recipeCategoriesSearchRequest,
        );
    }

    const customerGroups = await userFlow.getB2BContextCustomerGroups();

    const device = Browser.platformType();
    const response = await searchDirectApi.searchGlobal(
        loopGlobalSearchRequest,
        searchRequest.storeId,
        abortSignal,
        customerGroups,
        device,
        quickSearch,
    );
    const mappedResponse = mapLoopGlobalSearchResponse(response, searchRequest.searchTerm);
    return mappedResponse;
};

const searchByAttribute = async (
    searchRequest: SearchByAttributeRequest,
): Promise<LoopNoQueryProductResults> => {
    const loopSearchRequest: ApiLoopSearchByAttributeRequest = {
        attribute: searchRequest.attribute,
        requestAlias: searchRequest.requestAlias
            ? {
                  name: searchRequest.requestAlias.name,
                  value: searchRequest.requestAlias.value,
                  details: searchRequest.requestAlias.details,
              }
            : undefined,
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            sortBy: mapSortOrder(searchRequest.sortBy),
            facets: mapFacets(searchRequest.facets),
        },
        customData: {
            getEntitiesByAttributeABTest:
                searchRequest.customData?.getEntitiesByAttributeABTest || false,
        },
    };

    const customerGroups = await userFlow.getB2BContextCustomerGroups();

    const device = Browser.platformType();
    const response = await searchDirectApi.searchByAttribute(
        loopSearchRequest,
        searchRequest.storeId,
        customerGroups,
        device,
    );

    DirectProductProvider.instance.insertItems(response.results.items, searchRequest.storeId);
    const mappedResponse = mapLoopProductNoQuerySearchResponse(response);

    return mappedResponse;
};

const searchByAttributeLoop = async (
    attribute: {
        name: string;
        value: string;
    },
    resultsPerPage: number,
    selectedPage: number,
    selectedBrand: string[] | null,
    selectedSortOrder: string | null,
    selectedEnvironmentalLabels: string[] | null,
    storeId: string,
    personalizeProductsByAttribute: boolean | null,
    requestAlias?: SearchRequestAlias,
) => {
    const sortBy = selectedSortOrder ? [selectedSortOrder] : [];
    const facets = [
        {
            type: SearchFacetType.Brand,
            selected: selectedBrand || [],
        },
        {
            type: SearchFacetType.EnvironmentalLabels,
            selected: selectedEnvironmentalLabels || [],
        },
    ];

    const currentPage = selectedPage > 0 ? selectedPage - 1 : 0;

    return searchByAttribute({
        attribute,
        sortBy,
        resultsPerPage,
        facets,
        page: currentPage,
        storeId,
        customData: {
            getEntitiesByAttributeABTest: personalizeProductsByAttribute || false,
        },
        requestAlias,
    });
};

const autocomplete = async (
    searchTerm: string,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    skip: number = 0,
    // eslint-disable-next-line @typescript-eslint/default-param-last
    take: number = 5,
    abortSignal: AbortSignal | undefined,
    searchService: SearchService | null,
    storeId: string,
) => {
    const loopAutocompleteRequest: ApiLoopAutocompleteRequest = {
        query: searchTerm,
        queriesOptions: { skip, take },
    };

    const customerGroups = await userFlow.getB2BContextCustomerGroups();

    const device = Browser.platformType();

    const response = await searchDirectApi.autoComplete(
        loopAutocompleteRequest,
        abortSignal,
        storeId,
        searchService,
        customerGroups,
        device,
    );

    const mappedResponse = mapLoopAutocompleteResponse(response, searchTerm);
    return mappedResponse;
};

const searchProductsInSection = async (
    attribute: {
        name: string;
        value: string;
    },
    resultsPerPage: number,
    selectedPage: number,
    selectedBrand: string[],
    selectedSortOrder: string | null,
    selectedEnvironmentalLabels: string[],
    storeId: string,
    personalizeProductsByAttribute: boolean | null,
    requestAlias?: SearchRequestAlias,
): Promise<LoopNoQueryProductResults> => {
    const loopResults = await searchByAttributeLoop(
        attribute,
        resultsPerPage,
        selectedPage,
        selectedBrand,
        selectedSortOrder,
        selectedEnvironmentalLabels,
        storeId,
        personalizeProductsByAttribute,
        requestAlias,
    );

    return loopResults;
};

const searchPointshopProductsInPointRange = async (
    minPointsValue: number,
    maxPointsValue: number,
    searchRequest: {
        page: number;
        resultsPerPage: number;
    },
) => {
    const rangeFacet: ApiLoopRangeFacet = {
        attributeName: 'pointPrice',
        type: 'range',
        selected: {
            min: minPointsValue,
            max: maxPointsValue,
        },
    };

    const loopSearchRequest: ApiLoopSearchRequest = {
        query: '',
        resultsOptions: {
            skip: searchRequest.page * searchRequest.resultsPerPage,
            take: searchRequest.resultsPerPage,
            facets: [rangeFacet],
        },
    };

    const response = await searchDirectApi.searchLoyaltyProducts({ loopSearchRequest });
    const mapped = response.results.items
        .filter((x) => x.type === 'PointshopEntity')
        .map(mapLoopSearchPointshop);

    return mapped;
};

export type SearchService = 'aiSearchService' | 'loop54SearchService_v1';

const searchFlow = {
    searchProductsLoop,
    searchProductOffersLoop,
    searchArticlesLoop,
    searchStoresLoop,
    searchGlobalLoop,
    searchByAttribute,
    autocomplete,
    searchProductsInSection,
    searchByAttributeLoop,
    searchRecipesLoop,
    searchLoyaltyProductsById,
    searchLoyaltyProducts,
    searchPointshopProductsInPointRange,
};

export default searchFlow;
