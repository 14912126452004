import { CloseIcon, SearchIcon } from '@coop/icons';
import classNames from 'classnames';
import * as React from 'react';

import { ChipIconButton } from '../Chip';
import { Icon } from '../Icon';
import styles from './Search.module.scss';

interface SearchContentProps {
    inputPlaceholder: string;
    inputText?: string;
    defaultValue?: string;
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onInputClick?: (e: React.MouseEvent) => void;
    onResetClick?: (e: React.MouseEvent) => void;
    onInputFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onInputBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onSearchIconPress?: () => void;
    'aria-haspopup'?: React.ComponentPropsWithoutRef<'input'>['aria-haspopup'];
    'aria-label'?: React.ComponentPropsWithoutRef<'input'>['aria-label'];
}

const SearchContent = React.forwardRef<HTMLInputElement, SearchContentProps>((props, inputRef) => {
    return (
        <div className={classNames(styles['Search-content'], 'u-noPrint')}>
            <div
                aria-hidden
                className={classNames(styles['Search-icon'], styles['Search-iconGlass'])}
            >
                <Icon icon={SearchIcon} title="Sök" />
            </div>
            <input
                ref={inputRef}
                className={styles['Search-input']}
                type="search"
                enterKeyHint="search"
                defaultValue={props.defaultValue}
                placeholder={props.inputPlaceholder}
                onChange={props.onInputChange || undefined}
                value={props.inputText}
                onFocus={props.onInputFocus || undefined}
                onBlur={props.onInputBlur || undefined}
                onKeyDown={props.onKeyDown || undefined}
                onClick={props.onInputClick || undefined}
                data-testid="search-input"
                size={1} // Enables it to shrink below default 20 which is needed for small screens
                aria-haspopup={props['aria-haspopup']}
                aria-label={props['aria-label']}
            />
            {props.inputText && (
                <ChipIconButton
                    icon={CloseIcon}
                    className={styles['Search-iconReset']}
                    label="Rensa"
                    theme="whiteGreen"
                    size={24}
                    onClick={(e) => props.onResetClick?.(e)}
                />
            )}
        </div>
    );
});

export default SearchContent;
