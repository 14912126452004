/* eslint-disable react/jsx-props-no-spreading */
import type { FC } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import ComponentPortals from '../../../common/components/molecules/ComponentPortals';
import { ga4SearchTracking } from '../../../common/tracking/ga4/search';
import { useSearchStateContext } from '../../../search/contexts/searchStateContext';
import type { GlobalSearchResults } from '../../../search/models/models';
import { GlobalSearchInContent } from '../GlobalSearchInContent';
import Header from '../Header';
import type { GlobalHeaderDOMProps } from './GlobalHeader.types';
import { getSearchDefaultContentLinks, getTypedCurrentPage } from './GlobalHeader.utils';
import GlobalSearchBar from './GlobalSearchBar';
import GlobalSearchContext from './GlobalSearchContext';
import GlobalSearchOverlay from './GlobalSearchOverlay';

interface GlobalHeaderProps extends GlobalHeaderDOMProps {
    query?: string;
    hideB2BSwitch?: boolean;
    performFullSearch?: (query: string, originalQuery?: string) => void;
    stayAfterLoginLogoutRedirect?: boolean;
}

const GlobalHeader: FC<React.PropsWithChildren<GlobalHeaderProps>> = (props) => {
    const initialQuery = props.query || '';
    const typedCurrentPage = getTypedCurrentPage(props.currentPage);

    return (
        <GlobalSearchContext initialQuery={initialQuery} searchFromPage={typedCurrentPage}>
            <GlobalHeaderInner {...props} />
        </GlobalSearchContext>
    );
};

const shouldOverrideToggleSearch = (pathname: string) => {
    if (!pathname) {
        return false;
    }
    return (
        pathname.startsWith('/medlem') ||
        pathname.startsWith('/coop-kundservice') ||
        pathname.startsWith('/hallbarhet') ||
        pathname.startsWith('/Globala-sidor') ||
        pathname.startsWith('/bank-och-betalkort') ||
        pathname.startsWith('/pa-jobbet')
    );
};

const GlobalHeaderInner: FC<React.PropsWithChildren<GlobalHeaderProps>> = (props) => {
    const { open, isOpen, searchFromPage } = useSearchStateContext<GlobalSearchResults>();
    const { pathname } = useLocation();

    const handleSearchToggleClick = () => {
        ga4SearchTracking.searchIconClick(searchFromPage);
        open();
    };

    const overrideToggleSearch = shouldOverrideToggleSearch(pathname);
    const enableToggleSearch = props.useToggledSearch || overrideToggleSearch;

    const shouldShowGlobalSearchBar = !props.searchDisabled && !enableToggleSearch;

    return (
        <>
            <Header
                stayAfterLoginLogoutRedirect={props.stayAfterLoginLogoutRedirect || false}
                showB2BSwitch={!props.hideB2BSwitch}
                searchBarInHeaderSlot={
                    shouldShowGlobalSearchBar && (
                        <GlobalSearchBar performFullSearch={props.performFullSearch} size="small" />
                    )
                }
                preHeaderText={props.preHeaderText}
                featuredNavigationItems={props.featuredNavigation?.items}
                useSearchToggler={enableToggleSearch}
                onSearchToggleClick={handleSearchToggleClick}
                searchToggleIsActive={isOpen}
                headerTheme={props.headerTheme}
                preHeaderTheme={props.preHeaderTheme}
            />
            <GlobalSearchOverlay
                shortcutLinks={getSearchDefaultContentLinks(searchFromPage)}
                performFullSearch={props.performFullSearch}
            />

            <ComponentPortals
                cmpName="SearchInContent"
                render={() => (
                    <GlobalSearchInContent
                        performFullSearch={props.performFullSearch}
                        globalSearchDisabled={!shouldShowGlobalSearchBar}
                    />
                )}
            />

            {props.children}
        </>
    );
};

export default GlobalHeader;
