import type { QueryKey } from '@tanstack/react-query';

import { ensureLeadingSlash, trimEnd } from '../common/utils/stringUtils';
import type { ContentParams } from './api/contentDeliveryApi';

const EPI_PAGE_KEY = 'epiPage';
const BY_URL_KEY = 'byUrl';
const BY_CONTENT_REFERENCE_KEY = 'byContentReference';

const getEpiPageByUrlQueryKey = <T extends IContent>(
    route: string,
    params: ContentParams<T> | undefined = undefined,
) => [...epiPageQueryKeys.byUrl, ensureLeadingSlash(trimEnd(route, '/')), params] as const;

const getEpiPageByContentReferenceQueryKey = <T extends IContent>(
    contentReferenceId: string,
    params: ContentParams<T>,
) => [...epiPageQueryKeys.byContentReference, contentReferenceId, params] as const;

export const epiPageQueryKeys = {
    all: [EPI_PAGE_KEY] as const,
    byUrl: [EPI_PAGE_KEY, BY_URL_KEY] as const,
    byContentReference: [EPI_PAGE_KEY, BY_CONTENT_REFERENCE_KEY] as const,
    getByUrl: getEpiPageByUrlQueryKey,
    getByContentReference: getEpiPageByContentReferenceQueryKey,
};

export const isEpiPageByUrlQueryKey = (
    queryKey: QueryKey,
): queryKey is ReturnType<typeof epiPageQueryKeys.getByUrl> =>
    Array.isArray(queryKey) && queryKey[0] === EPI_PAGE_KEY && queryKey[1] === BY_URL_KEY;

export const isEpiPageByContentReferenceQueryKey = (
    queryKey: QueryKey,
): queryKey is ReturnType<typeof epiPageQueryKeys.getByContentReference> =>
    Array.isArray(queryKey) &&
    queryKey[0] === EPI_PAGE_KEY &&
    queryKey[1] === BY_CONTENT_REFERENCE_KEY;
