/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { appConfig } from '../../../../../appConfig';
import type { CoopContentPageSize } from '../../../../../utility/coopSwiperBreakpoints';
import type { PriceType } from '../../../models/priceData/priceData';
import persistance from '../../../persistance/persistance';
import type { FeatureFlagsState, SharedUiState } from '../../structureDefinitions/uiState';

interface LayoutSizePayload {
    size: CoopContentPageSize;
}

interface PriceTypePayload {
    preferredPriceType: PriceType;
}

const getInitialState = (): SharedUiState => {
    const userId = appConfig.coopUserSettings.shoppingUserId;
    const preferredPriceType = persistance.priceTypeStorage.getCurrent(userId);

    let defaultSharedUiState: SharedUiState = {
        hasSideBar: false,
        hideFooterMobile: false,
        hideSubMenu: false,
        hideLoginReminder: false,
        layoutSize: 'default',
        contentPageSize: 'default',
        userName: null,
        featureFlags: {
            hidePreHeader: !appConfig.coopSettings.featureFlags.enableB2B,
        },
        preferredPriceType,
    };

    const domProps = document.getElementById('layoutSettings')?.innerText;
    if (domProps) {
        const parsedLayoutSettings = JSON.parse(domProps) as Partial<SharedUiState>;
        if (parsedLayoutSettings) {
            defaultSharedUiState = { ...defaultSharedUiState, ...parsedLayoutSettings };
        }
    }

    return defaultSharedUiState;
};

const slice = createSlice({
    name: 'shared',
    initialState: getInitialState(),
    reducers: {
        setContentPageSize: (state: SharedUiState, action: PayloadAction<LayoutSizePayload>) => {
            return { ...state, contentPageSize: action.payload.size };
        },
        setPriceType: (state: SharedUiState, action: PayloadAction<PriceTypePayload>) => {
            return { ...state, preferredPriceType: action.payload.preferredPriceType };
        },
        setFeatureFlag: (
            state: SharedUiState,
            action: PayloadAction<Partial<FeatureFlagsState>>,
        ) => {
            return { ...state, featureFlags: { ...state.featureFlags, ...action.payload } };
        },
    },
});

const actions = { ...slice.actions };
export const sharedUiActions = actions;

export default slice.reducer;
