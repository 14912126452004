import { useLocation } from 'react-router-dom';

import { appConfig } from '../../../appConfig';
import { Cookies } from '../../../utility/cookies';
import { BUSINESS_TOGGLE_COOKIE } from '../persistance/cookie/cookieKeys';
import { trimSlashes } from './stringUtils';

export const B2B_SEGMENT = appConfig.coopSettings.b2b.startPage
    ? trimSlashes(appConfig.coopSettings.b2b.startPage.url)
    : 'notsetup';

export const isB2BMode = () => {
    if (appConfig.coopSettings.featureFlags.enableB2B) {
        return isB2BWindowLocation();
    }

    return Cookies.read(BUSINESS_TOGGLE_COOKIE);
};

const getIsRouteB2B = (pathName: string) => {
    const firstSegmentsOfB2Broutes = appConfig.coopSettings.b2b.b2bRoutes
        ?.map(trimSlashes)
        .map((segments) => segments.split('/')[0]);

    const currentPathnameFirstSegment = trimSlashes(pathName).split('/')[0];

    // check only first segments of the urls to automatically include all their child pages
    const isB2BRoute = firstSegmentsOfB2Broutes.some(
        (item) => item.toLowerCase() === currentPathnameFirstSegment.toLowerCase(),
    );
    return isB2BRoute;
};

export const isB2BWindowLocation = () => {
    return getIsRouteB2B(window.location.pathname);
};

export const useIsB2BRoute = () => {
    const isWindowB2BRoute = isB2BWindowLocation();
    const isRouterB2BRoute = getIsRouteB2B(useLocation().pathname);

    return isWindowB2BRoute || isRouterB2BRoute;
};

/**
 * Cross-context means that a user is on a site that is not intended for their user type.
 * So a private user on the company site or a company user on the private site.
 */
export const isInB2BCrossContext = ({
    isB2BRoute,
    isUserCompany: isCompany,
    isAuthenticated,
}: {
    isB2BRoute: boolean;
    // if the user is a company user
    isUserCompany: boolean;
    // if the user is authenticated
    isAuthenticated: boolean;
}) => {
    const privateUserOnCompanySite =
        appConfig.coopSettings.featureFlags.enableB2B &&
        isAuthenticated &&
        !isCompany &&
        isB2BRoute;

    const companyUserOnPrivateSite =
        appConfig.coopSettings.featureFlags.enableB2B &&
        isAuthenticated &&
        isCompany &&
        !isB2BRoute;

    return {
        privateUserOnCompanySite,
        companyUserOnPrivateSite,
    };
};
