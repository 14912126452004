import type { QueryCache } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';

import {
    epiPageQueryKeys,
    isEpiPageByContentReferenceQueryKey,
    isEpiPageByUrlQueryKey,
} from '../../episerver/epiQueryKeys';

export const coopQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

coopQueryClient.getQueryCache().subscribe((event) => {
    const cache = coopQueryClient.getQueryCache();
    if (event.type === 'added') {
        checkRedundantEpiPaqeQueries(cache);
    }
});

const checkRedundantEpiPaqeQueries = (cache: QueryCache) => {
    const allEpiPageQueryKeys = cache
        .findAll({ queryKey: epiPageQueryKeys.all })
        .map((item) => item.queryKey);

    if (allEpiPageQueryKeys.length > 0) {
        const keysByUrl = allEpiPageQueryKeys.filter(isEpiPageByUrlQueryKey);
        checkRedundentEpiPaqeQueryByDepthParam(keysByUrl);

        const keysByContentReference = allEpiPageQueryKeys.filter(
            isEpiPageByContentReferenceQueryKey,
        );
        checkRedundentEpiPaqeQueryByDepthParam(keysByContentReference);
    }
};

/**
 * Reduntant means some queries are the same in all aspects except depth parameter.
 */
const checkRedundentEpiPaqeQueryByDepthParam = (
    allKeys: (
        | ReturnType<typeof epiPageQueryKeys.getByUrl>
        | ReturnType<typeof epiPageQueryKeys.getByContentReference>
    )[],
) => {
    const isQueryKeyNearlyEqual = (key1: (typeof allKeys)[0], key2: (typeof allKeys)[0]) => {
        return (
            (key1[1] === 'byUrl' || key1[1] === 'byContentReference') && // for TS to fail if query key type changes
            key1[1] === key2[1] &&
            key1[2] === key2[2] && // the same url or content reference
            key1[3]?.depth !== key2[3]?.depth // depth is different
        );
    };

    const unique: (typeof allKeys)[0][] = [];
    allKeys.forEach((queryKey) => {
        if (unique.find((x) => isQueryKeyNearlyEqual(x, queryKey))) {
            // eslint-disable-next-line no-console
            console.warn(
                'Coop.se performance warning: found multiple query keys for the same content, but with different "depth" parameter:',
                '\n',
                queryKey,
            );
        } else {
            unique.push(queryKey);
        }
    });
};
