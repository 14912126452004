/* eslint-disable no-param-reassign */
import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { appConfig } from './appConfig';
import persistance from './microApps/common/persistance/persistance';
import { GdprCookieVendors, triggerWhenGDPRConsentExists } from './utility/gdpr';

const instrumentationKey = appConfig.coopSettings.applicationInsightsKey || '';

const ai = new ApplicationInsights({
    config: {
        instrumentationKey,
        maxBatchInterval: 15000, // be careful changing this, each log call will call getStoreCOntext, which is quite slow
        disableFetchTracking: false,
        disableFlushOnBeforeUnload: true,
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        enableAjaxErrorStatusText: true,
        enableUnhandledPromiseRejectionTracking: true,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: [
            '*.consentmanager.net',
            'delivery.consentmanager.net',
            'gtm.coop.se',
            'www.google-analytics.com',
            appConfig.coopSettings.serviceAccess?.ebiDigitalReceiptsApiUrl?.replace('https://', ''),
            '*.dynamicyield.com',
            '*.dynamicyield.eu',
            'direct.dy-api.eu',
            'api.ebilobster.ai',
            '*.zdassets.com',
            '*.zendesk.com',
            '*.northfork.se',
            'sitegainer.com',
            '*.northfork.se',
            '*.googleapis.com',
        ],
    },
});

const excludedDomainsFromLogs = [
    'google-analytics.com',
    'hotjar.io',
    'hotjar.com',
    'recs.richrelevance.com',
    'stats.g.doubleclick.net',
    'consentmanager.mgr.consensu.org',
    'code.upscope.io',
    'gtm.coop.se',
    'facebook.com',
    'ebilobster.ai',
    'dynamicyield.com',
    'zdassets.com',
    'zendesk.com',
];

const sustainabilityFilter = (item: ITelemetryItem) => {
    if (
        item.baseData?.name?.includes('articleservice/search/articles/sustainability') &&
        item?.baseData?.responseCode === 404
    ) {
        return false;
    }

    return true;
};

const telemetryFilters: Array<(item: ITelemetryItem) => boolean> = [sustainabilityFilter];

const telemetryInitializer = (item: ITelemetryItem): void | boolean => {
    const target: string = item.baseData?.target;
    if (target) {
        if (excludedDomainsFromLogs.some((excludedDomain) => target.includes(excludedDomain))) {
            return false;
        }
    }

    if (item.data) {
        // eslint-disable-next-line no-param-reassign
        const storeContext = persistance.storeContext.getCached();
        if (storeContext) {
            // eslint-disable-next-line no-param-reassign
            item.data.productionUnit = storeContext.productionUnitId;
            if (storeContext.zipCode) {
                // eslint-disable-next-line no-param-reassign
                item.data.zipCode = storeContext.zipCode;
            }
            if (storeContext.pickupPointId) {
                // eslint-disable-next-line no-param-reassign
                item.data.pickupPointId = storeContext.pickupPointId;
            }
        }
    }

    // ajax dependencies that are cancelled by browser have a result code 0 and are presented as errors
    // Filter them to keep App Insights cleaner and lower error rate
    if (item?.baseData?.responseCode === 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-param-reassign
        (<any>item).baseData.success = true;
    }

    const shouldSendTelemetry = telemetryFilters.reduce((filterFlag, filter) => {
        // if any filtering function returned false, short circuit
        if (!filterFlag) {
            return filterFlag;
        }

        return filter(item);
    }, true);

    return shouldSendTelemetry;
};
let isAiLoaded = false;
const loadAi = () => {
    isAiLoaded = true;
    ai.loadAppInsights();
    if (appConfig.coopUserSettings.userId) {
        ai.setAuthenticatedUserContext(appConfig.coopUserSettings.userId);
    }
    ai.addTelemetryInitializer(telemetryInitializer);
};

const initAi = () => {
    const userIdCookie = ai.getCookieMgr().get('ai_user');
    const hasEnabledCookies = typeof userIdCookie === 'string' && userIdCookie.length > 0;
    if (hasEnabledCookies) {
        loadAi();
    }
};

const enableCookie = () => {
    if (!isAiLoaded) {
        loadAi();
    }
};

const disableCookie = () => {
    ai.config.disableTelemetry = true;
    ai.getCookieMgr().setEnabled(true);
    ai.getCookieMgr().del('ai_session');
    ai.getCookieMgr().del('ai_user');
    ai.getCookieMgr().setEnabled(false);
    ai.clearAuthenticatedUserContext();
};

triggerWhenGDPRConsentExists({
    cookieVendor: GdprCookieVendors.MicrosoftAzure,
    onConsentExists: enableCookie,
    onActiveDecline: disableCookie,
});

initAi();

export { ai as appInsights };
