import { appConfig } from '../../../appConfig';
import { DefaultProductSection } from '../../../models/ecommerce/product/productSectionModel';
import { Helpers } from '../../../utility/helpers';
import type { ProductBadgeProps, ProductProps } from '../models/props/itemTeaserProps';

export const isAttributeEko = (identifier: string) => {
    return identifier === 'EKO_SV' || identifier === 'EKO';
};
export const isAttributeExcluded = (identiier: string) => {
    return appConfig.coopSettings.ecommerce?.hiddenProductLabelsIds?.includes(identiier);
};

export const isAttributeNicotine = (identifier: string) => {
    return identifier?.toLowerCase() === 'nikotinlakemedel';
};

export const isAttributeAgeRestriction = (identifier: string) => {
    return identifier?.toLowerCase() === '18' || identifier?.toLowerCase() === '18arlakemedel';
};

export const getAgeRestrictionBadge = (
    product: ApiProduct | undefined,
): ProductBadgeProps | undefined => {
    const ageRestrictionAttribute = product?.details?.attributes.find((attr) =>
        isAttributeAgeRestriction(attr.identifier),
    );

    if (ageRestrictionAttribute) {
        return {
            alt: ageRestrictionAttribute.name,
            url: ageRestrictionAttribute.image?.url,
        };
    }

    return undefined;
};

export const filterProductAttributes = (attributes: ApiProductAttribute[]) => {
    const isKrav = (identifier: string) =>
        identifier === 'KRAV0U0MARK' || identifier === 'KRAV_MARK';
    const isEuOrganic = (identifier: string) =>
        identifier === 'EU0U0ORGANIC0U0FARMING' || identifier === 'EU_ORGANIC_FARMING';

    let filteredAttributes = [...attributes];

    // If both KRAV and EKO is on product, only KRAV should be shown.
    if (filteredAttributes.some((attribute) => isKrav(attribute.identifier))) {
        filteredAttributes = filteredAttributes.filter(
            (attribute) =>
                !isAttributeEko(attribute.identifier) && !isEuOrganic(attribute.identifier),
        );
    }

    // If both EKO and Ekologiskt is on product, only EKO should be shown.
    if (filteredAttributes.some((attribute) => isAttributeEko(attribute.identifier))) {
        filteredAttributes = filteredAttributes.filter(
            (attribute) => !isEuOrganic(attribute.identifier),
        );
    }

    filteredAttributes = filteredAttributes.filter((item) => !isAttributeExcluded(item.identifier));

    return attributes;
};

export const getTopProductSection = (product: ApiProduct): ApiProductSection => {
    if (!product) return new DefaultProductSection();
    if (!product.sections || !product.sections.length) {
        return new DefaultProductSection();
    }
    return product.sections[0][0];
};

export const mapProductToEcomPromotion = (product?: ApiProduct): EcomPromotion | undefined => {
    if (product && product.promos && product.promos.length > 0) {
        const { promos } = product;
        // Assortment API sort this by priority desc and picks the first one, so highest priority wins
        // They use that high prio promo to populate promotionPriceData on product
        const promoHighestPriority = promos
            .slice()
            .sort((a, b) => (b.priority || 0) - (a.priority || 0))[0];

        if (promoHighestPriority) {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const { priority, ...rest } = promoHighestPriority;
            return rest;
        }
    }

    return undefined;
};

export const mapProductToProps = (product: ApiProduct): ProductProps => {
    const promotion = mapProductToEcomPromotion(product);

    const productData: ProductProps = {
        identifier: product.identifier,
        eanCode: product.ean,
        imageUrl: product.image ? product.image.url : undefined,
        name: product.name,
        url: product.url,
        attributeLabels: product.details?.attributes || [],
        healthattributeLabels: product.details?.healthAttributes || [],
        healthSafetySignalWordsCode: product.details?.healthSafetySignalWordsCode || [],
        healthSafetyPrecautionaryStatementText:
            product.details?.healthSafetyPrecautionaryStatementText || [],
        healthSafetySignalStatementText: product.details?.healthSafetySignalStatementText || [],
        promotion,
        comparativePriceData: product.comparativePriceData,
        comparativeText: product.comparativeText,
        fromSweden: product.details?.fromSweden || false,
        manufacturer: product.manufacturer,
        variants: product.variants,
        consumerInformationText: product.details?.consumerInformationText,
        packageSizeInformation: product.details?.size?.packageSizeInformation,
        priceData: product.priceData,
        promotionPriceData: product.promotionPriceData,
        recycleFeeData: product.details?.recycleFeeData,
        declarationOfOrigin: product.details?.declarationOfOrigin,
        countriesOfOrigin: product.details?.countryOfOriginCodes,
        description: product.details?.description,
        nutrientInformation: product.details?.nutrientInformation,
        nutritionFacts: product.details?.nutritionFacts,
        listOfIngredients: product.details?.content,
        listOfNonFoodIngredients: product.details?.nonFoodIngredientStatement,
        maxStorageTemperature: product.details?.maximumStorageTemperature,
        catchData: product.details?.catchData,
        storageState: product.details?.storageStateCode,
        isMagazine: product.isMagazine,
        isNicotineProduct: product.details?.isNicotineProduct,
        isPharmaceutical: product.details?.pharmaceuticalData?.isPharmaceutical,
        historicalPriceData: product.historicalPriceData,
        compulsoryAdditiveLabel: product.compulsoryAdditiveLabelInformation,
        consumerInstructions: product.consumerInstructions,
        replacementCountries: product.replacementCountries,
        originCountry: product.originCountry,
        variety: product.variety,
        class: product.class,
        preparationInstructionsList: product.preparationInstructionsList,
        sustainabilityInfo: product.details?.sustainabilityInfo?.productScore,
        sustainabilityInfoApplicable: product.details?.sustainabilityInfoApplicable,
        regulatedProductName: product.regulatedProductName,
        animalFoodData: product.animalFoodData,
    };

    return productData;
};

export const isProductObjectsEqualPredicate =
    (product1: { productId: string; variantId?: string }) =>
    (product2: { productId: string; variantId?: string }) => {
        return isProductEqualPredicate(product1.productId, product1.variantId)(
            product2.productId,
            product2.variantId,
        );
    };

export const isProductEqualPredicate =
    (product1Id: string, variant1Id?: string) => (product2Id: string, variant2Id?: string) => {
        return (
            product1Id === product2Id && (variant1Id === variant2Id || (!variant1Id && !variant2Id))
        );
    };

export const getFormattedPackagingInfo = (
    variantName?: string,
    packageSizeInformation?: string,
) => {
    const formatted =
        (variantName && `${variantName} `) ||
        (packageSizeInformation && `${packageSizeInformation}`) ||
        undefined;

    return formatted;
};

export const getFormattedComparativePrice = (
    comparativePrice?: number,
    comparativeText?: string,
) => {
    const formatted =
        (comparativePrice &&
            comparativeText &&
            `Jfr-pris: ${Helpers.toEcommercePrice(comparativePrice)} ${comparativeText}`) ||
        null;

    return formatted;
};
