/* eslint-disable @typescript-eslint/no-explicit-any */
export const failSilently =
    <CallbackFunction extends (...args: any[]) => any>(
        callback: CallbackFunction,
        callbackWhenError?: () => void,
    ) =>
    (...params: Parameters<CallbackFunction>) => {
        try {
            return callback(...params) as ReturnType<CallbackFunction>;
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.error('Silent fail: ', ex);
            callbackWhenError?.();
        }
    };
