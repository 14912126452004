/* eslint-disable no-console */
import type { QueryKey } from '@tanstack/react-query';

import { epiPageQueryKeys } from '../../episerver/epiQueryKeys';

const errorPrefix = 'Coop.se performance warning: Invalid initial query data found in DOM.';

export const getInitialQueryDataFromDOM = (
    container: HTMLElement | null,
): { key: QueryKey; data: unknown } | undefined => {
    if (!container) {
        return;
    }

    const domModelString = container.innerText;
    const domDepth = container.dataset?.depth;
    const domType = container.dataset?.type;
    const domUrl = container.dataset?.url;
    const domContentReference = container.dataset?.contentReference;

    if (!domModelString) {
        console.warn(errorPrefix, 'No serialized model found.');
        return undefined;
    }

    let domModel;
    try {
        domModel = JSON.parse(domModelString);
    } catch (error) {
        console.warn(errorPrefix, 'Model could not be parsed.');
        return undefined;
    }

    if (!domDepth) {
        console.warn(errorPrefix, 'No depth parameter found.');
        return undefined;
    }

    switch (domType?.toLowerCase()) {
        case 'ByUrl'.toLowerCase(): {
            if (domUrl) {
                return {
                    key: epiPageQueryKeys.getByUrl(domUrl, {
                        depth: parseInt(domDepth, 10),
                    }),
                    data: domModel,
                };
            }
            console.warn(errorPrefix, 'No url found.');
            return undefined;
        }
        case 'ByContentReference'.toLowerCase(): {
            if (domContentReference) {
                return {
                    key: epiPageQueryKeys.getByContentReference(domContentReference, {
                        depth: parseInt(domDepth, 10),
                    }),
                    data: domModel,
                };
            }
            console.warn(errorPrefix, 'No ContentReference found.');
            return undefined;
        }
        default: {
            console.warn(errorPrefix, `Unknown type found: ${domType}.`);
            return undefined;
        }
    }
};
