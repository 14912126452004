import type { FC } from 'react';
import * as React from 'react';
import type { AsyncStateStatus } from 'react-async-hook';

import type { SearchFromPageType } from '../../base/models/SearchFromPageType';
import type { ApiException } from '../../common/exceptions/exceptionDefinitions';
import { useSearchDropdownState } from '../../common/hooks/useSearchDropdownState';
import type { SearchResultType } from '../models/SearchResultType';
import { mapSearchFromPageToExpectedResultType } from '../utils/searchResultUtils';

interface SearchDropdownState<TResult> {
    result?: TResult;
    a11yResultAnnounceMessage: string;
    isOpen: boolean;
    error?: ApiException;
    show: boolean;
    query?: string;
    status: AsyncStateStatus;
    searchFromPage?: SearchFromPageType;
    expectedSearchResultType?: SearchResultType;
    isLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchDropdownStateContext = React.createContext<SearchDropdownState<any>>({
    isOpen: false,
    result: undefined,
    a11yResultAnnounceMessage: '',
    error: undefined,
    show: false,
    query: undefined,
    searchFromPage: undefined,
    expectedSearchResultType: undefined,
    status: 'not-requested',
    isLoading: false,
});

const contextNotInitialized = () => {
    throw new Error('SearchDropdownDispatchContext not yet initialized');
};

type SearchDropdownDispatch = Pick<
    ReturnType<typeof useSearchDropdownState>,
    'setQuery' | 'resetQuery' | 'open' | 'close' | 'clear' | 'setA11yResultAnnounceMessage'
>;
const SearchDropdownDispatchContext = React.createContext<SearchDropdownDispatch>({
    setQuery: contextNotInitialized,
    resetQuery: contextNotInitialized,
    open: contextNotInitialized,
    close: contextNotInitialized,
    clear: contextNotInitialized,
    setA11yResultAnnounceMessage: contextNotInitialized,
});

interface SearchStateProviderProps {
    initialQuery: string | null;
    searchFromPage?: SearchFromPageType;
    searchFunction: (signal: AbortSignal, query: string) => Promise<unknown>;
    minimumQueryLength: number;
    debounceTime?: number;
}
export const SearchStateProvider: FC<React.PropsWithChildren<SearchStateProviderProps>> = ({
    children,
    searchFunction,
    searchFromPage,
    initialQuery,
    minimumQueryLength,
    debounceTime,
}) => {
    const {
        result,
        resultDescription,
        isOpen,
        clear,
        open,
        close,
        error,
        show,
        query,
        setQuery,
        resetQuery,
        status,
        isLoading,
        setA11yResultAnnounceMessage,
    } = useSearchDropdownState(initialQuery, searchFunction, minimumQueryLength, debounceTime);

    const expectedSearchResultType = mapSearchFromPageToExpectedResultType(searchFromPage);

    return React.createElement(
        SearchDropdownDispatchContext.Provider,
        {
            value: {
                clear,
                open,
                close,
                setQuery,
                resetQuery,
                setA11yResultAnnounceMessage,
            },
        },
        React.createElement(
            SearchDropdownStateContext.Provider,
            {
                value: {
                    result,
                    a11yResultAnnounceMessage: resultDescription,
                    isOpen,
                    error,
                    show,
                    query,
                    searchFromPage,
                    status,
                    isLoading,
                    expectedSearchResultType,
                },
            },
            children,
        ),
    );
};

export const useSearchStateContext = <TResult = unknown>() => {
    const state = React.useContext<SearchDropdownState<TResult>>(SearchDropdownStateContext);
    const dispatch = React.useContext(SearchDropdownDispatchContext);

    if (state === undefined || dispatch === undefined) {
        throw new Error('useSearchStateContext must be used within a SearchStateProvider');
    }
    return { ...state, ...dispatch };
};
