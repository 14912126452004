import * as React from 'react';

import USPNotice from './USPNotice';
import type { USPNoticeType } from './USPNotices.types';

interface USPNoticesProps {
    data: USPNoticeType[];
}

const USPNotices = (props: USPNoticesProps) => {
    if (!props.data?.length) return null;

    return (
        <div className="UspNotices">
            {props.data.map((notice) => (
                <USPNotice key={notice.id} text={notice.text} />
            ))}
        </div>
    );
};

export default USPNotices;
