import { appConfig } from '../../../appConfig';
import { urlFriendlySlug } from '../../common/utils/sanitizeUtils';
import { trimEnd } from '../../common/utils/stringUtils';
import type { SectionTreeItem } from '../components/SectionMenu';

export const buildEcommerceSectionUrl = (escapedNames: string[]) => {
    return `${trimEnd(appConfig.coopSettings.ecommerce.url, '/')}/varor/${escapedNames.join('/')}`;
};

export const buildAbsoluteUrl = (relativeUrl: string) => {
    return window.location.origin + trimEnd(relativeUrl, '/');
};

export const buildSubSectionUrl = (sectionTrail: SectionTreeItem[], sectionsUntilIndex: number) => {
    const subSections = sectionTrail
        .slice(0, sectionsUntilIndex + 1)
        .map((item) => item.escapedName);

    const url = buildEcommerceSectionUrl(subSections);
    return url;
};

export const buildProductUrl = (
    productData: { name: string; id: string },
    sections?: { id: string; escapedName?: string }[],
) => {
    if (!productData.name) {
        // eslint-disable-next-line no-console
        console.warn(`Incomplete product - name missing, product id: ${productData.id}`);
    }

    let productUrl = urlFriendlySlug(productData.name);
    const baseProductUrl = appConfig.coopSettings.ecommerce?.productBaseUrl;

    if (sections && sections.length > 0 && sections.every((category) => category.escapedName)) {
        const categoryUrl = sections.map((category) => category.escapedName).join('/');
        productUrl = `${baseProductUrl}${categoryUrl}/${productUrl}-${productData.id}`;
    } else {
        productUrl = `${baseProductUrl}${productUrl}-${productData.id}`;
    }

    return productUrl;
};

export const buildProductRedirectUrl = (productId: string) =>
    `/redirect/ecommerce/product/${productId}`;
