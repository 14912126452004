import classNames from 'classnames';
import type { PropsWithChildren, ReactNode } from 'react';
import * as React from 'react';

import { useDocumentPropertyHeightSetter } from '../../../common/hooks/useDocumentPropertyHeightSetter';
import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import { getMainContainerClassByLayout } from '../../utils/layoutUtils';

interface SearchInContentProps {
    leftSlot?: ReactNode;
    searchSlot: ReactNode;
    bottomRow?: ReactNode;
}

const SearchInContent = (props: PropsWithChildren<SearchInContentProps>) => {
    const layoutSize = useAppSelector((state) => state.ui.shared.layoutSize);
    const hasSideBar = useAppSelector((state) => state.ui.shared.hasSideBar);

    const ref = React.useRef<HTMLDivElement>(null);
    useDocumentPropertyHeightSetter('--searchInContentContainerHeight', ref);

    return (
        <div
            ref={ref}
            className={classNames(
                'SearchInContent',
                getMainContainerClassByLayout(layoutSize, hasSideBar),
            )}
            data-test="searchHeader"
        >
            {(props.leftSlot || props.searchSlot) && (
                <div className="SearchInContent--row u-noPrint">
                    {props.leftSlot && (
                        <div className="SearchInContent--cell SearchInContent--cellLeft">
                            {props.leftSlot}
                        </div>
                    )}

                    {props.searchSlot && (
                        <div
                            role="search"
                            className="SearchInContent--cell SearchInContent--cellSearch"
                        >
                            {props.searchSlot}
                        </div>
                    )}
                </div>
            )}
            {props.bottomRow && <div className="SearchInContent--row">{props.bottomRow}</div>}
        </div>
    );
};

export default SearchInContent;
