import type { CustomerData, CustomerDataMinimized } from '../../models/kimProxy/customerData';
import getDirectKimProxyAxiosClient from '../clients/directKimProxyAxiosClient';

export const kimProxyDirectApi = {
    getCustomerData: async (token: string): Promise<CustomerDataMinimized> => {
        const kimCustomerDataUrl = '/customer/data/me/';

        const response =
            await getDirectKimProxyAxiosClient(token).get<CustomerDataMinimized>(
                kimCustomerDataUrl,
            );

        const resp = response.data;
        return resp;
    },
    getCustomerDataFull: async (token: string): Promise<CustomerData> => {
        const kimCustomerDataUrl = '/customer/data/me/';

        const response =
            await getDirectKimProxyAxiosClient(token).get<CustomerData>(kimCustomerDataUrl);

        const resp = response.data;
        return resp;
    },
    deleteCustomerDataCache: async (token: string) => {
        const kimCustomerDataClearCacheUrl = '/customer/data/me/cache';

        await getDirectKimProxyAxiosClient(token).delete<boolean>(kimCustomerDataClearCacheUrl);
    },
};
