import type { SwiperOptions } from 'swiper/types';

import { isNumeric } from '../microApps/common/utils/numberUtils';
import { BREAKPOINT_MAX_TO, BREAKPOINT_MIN_FROM, Browser, BrowserSize } from './browser';

export type CoopLayoutSize = 'full' | 'default';

export type CoopContentPageSize = CoopLayoutSize | 'narrow';

export type SwiperBreakpoints =
    | {
          [BREAKPOINT_MIN_FROM]: SwiperOptions; // mandatory "from 0px" breakpoint
          [index: number]: SwiperOptions;
      }
    | undefined;

export class CoopSwiperBreakpoints {
    public static readonly AREA_BRAND_CATEGORY = 'BrandCategory';

    public static readonly AREA_LARGE_TEASER_LIST = 'LargeTeasersList';

    public static readonly AREA_LARGE_TEASER_GRID = 'LargeTeasersGrid';

    public static readonly AREA_BRAND_CATEGORY_LISTING = 'BrandCategoryListing';

    public static readonly AREA_HERO_PRODUCT = 'HeroProduct';

    public static readonly AREA_RECIPE = 'Recipe';

    public static readonly AREA_NEW_RECIPE_LIST = 'NewRecipeList';

    public static readonly AREA_MY_COOP = 'MyCoop';

    /** This is used in React Swiper LISTS of OLD design product teasers */
    public static readonly AREA_PRODUCT_LIST = 'ProductList';

    /** This is used in React Swiper LISTS of NEW design product teasers */
    public static readonly AREA_NEW_PRODUCT_LIST = 'NewProductList';

    /** This is used in React Swiper LISTS of NEW design product teasers WITH BANNER */
    public static readonly AREA_NEW_PRODUCT_LIST_WITH_BANNER = 'NewProductListWithBanner';

    /** This is used in GRIDS of OLD design product teasers, that are using ItemGrid component */
    public static readonly AREA_PRODUCT_GRID = 'ProductGrid';

    /** This is used in GRIDS of NEW design product teasers, that are using ItemGrid component */
    public static readonly AREA_NEW_PRODUCT_GRID = 'NewProductGrid';

    /** This is used in GRIDS of NEW React recipe lisiting block, when not using swiper, less than 6, centered and useNarrow */
    public static readonly AREA_RECIPE_LISTING_GRID = 'RecipeListingGrid';

    /** This is used in React Swiper Recipe Lisiting Block when using swiper and useNarrow */
    public static readonly AREA_RECIPE_LISTING_NARROW = 'RecipeListingSwiperNarrow';

    public static readonly AREA_DR_OFFERS_LIST = 'DrOffersList';

    /** This is used in weekly menu recipes blockin swiper list */
    public static readonly AREA_WEEKLY_MENU_LIST = 'WeeklyMenuList';

    public static readonly AREA_PERSONAL_OFFERS_LIST = 'PersonalOffersList';

    public static readonly AREA_TV_OFFERS_LIST = 'TVOffersList';

    public static readonly AREA_FOOTER_GRID = 'FooterGrid';

    static getBreakpointsFor(
        area: keyof SwiperBreakpointsCollection,
        hasSideMenu: boolean,
        pageContentSize: CoopContentPageSize,
        layoutSize: CoopLayoutSize,
    ): SwiperBreakpoints {
        const isViewportInconsistent = hasSideMenu || pageContentSize !== layoutSize; // this mean that page changes at SIDEBAR_HIDE_BREAKPOINT and .e. sidebar is hidden, so the breakpoints must also change

        const pageContentSizeOrFallback = pageContentSize || layoutSize;

        switch (pageContentSizeOrFallback) {
            case 'full':
                return FULL_WITH_SIDEMENU_BREAKPOINTS[area];
            case 'narrow':
                return isViewportInconsistent
                    ? NARROW_WITH_SIDEMENU_BREAKPOINTS[area]
                    : NARROW_WITHOUT_SIDEMENU_BREAKPOINTS[area];
            case 'default':
            default:
                return isViewportInconsistent
                    ? DEFAULT_WITH_SIDEMENU_BREAKPOINTS[area]
                    : DEFAULT_WITHOUT_SIDEMENU_BREAKPOINTS[area];
        }
    }
}

export const getSwiperBreakpoint = (browserSize: BrowserSize) => {
    return Browser.getBreakpoint(browserSize).from; // we always operate on the "from" values what describing Swiper breakponts, never use "to"
};

// The breakpoint where sidebar navigation gets hidden
const SIDEBAR_HIDE_BREAKPOINT = getSwiperBreakpoint(BrowserSize.lg);

export interface SwiperBreakpointsCollection {
    BrandCategory: SwiperBreakpoints;
    BrandCategoryListing: SwiperBreakpoints;
    LargeTeasersList: SwiperBreakpoints;
    LargeTeasersGrid: SwiperBreakpoints;
    HeroProduct: SwiperBreakpoints;
    Recipe: SwiperBreakpoints;
    MyCoop: SwiperBreakpoints;
    ProductList: SwiperBreakpoints;
    NewProductList: SwiperBreakpoints;
    NewProductListWithBanner: SwiperBreakpoints;
    ProductGrid: SwiperBreakpoints;
    NewProductGrid: SwiperBreakpoints;
    NewRecipeList: SwiperBreakpoints;
    RecipeListingGrid: SwiperBreakpoints;
    RecipeListingSwiperNarrow: SwiperBreakpoints;
    DrOffersList: SwiperBreakpoints;
    WeeklyMenuList: SwiperBreakpoints;
    PersonalOffersList: SwiperBreakpoints;
    TVOffersList: SwiperBreakpoints;
    FooterGrid: SwiperBreakpoints;
}

// I.e. Handla, Hallbarhet, Medlem, Bank
const FULL_WITH_SIDEMENU_BREAKPOINTS: SwiperBreakpointsCollection = {
    BrandCategory: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    LargeTeasersList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    LargeTeasersGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    BrandCategoryListing: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 2, slidesPerGroup: 2 },
    },
    HeroProduct: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    Recipe: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2.25, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 4.25, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 4.25, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6.25, slidesPerGroup: 6 },
    },
    MyCoop: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 4, slidesPerGroup: 4 },
        900: { slidesPerView: 6, slidesPerGroup: 6 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 4, slidesPerGroup: 4 },
        1200: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    ProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 4, slidesPerGroup: 4 },
        900: { slidesPerView: 6, slidesPerGroup: 6 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 4, slidesPerGroup: 4 },
        1200: { slidesPerView: 6, slidesPerGroup: 6 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 8, slidesPerGroup: 8 },
    },
    // mostly same as ProductList
    ProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 4, slidesPerGroup: 4 },
        900: { slidesPerView: 6, slidesPerGroup: 6 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 4, slidesPerGroup: 4 },
        1200: { slidesPerView: 6, slidesPerGroup: 6 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 8, slidesPerGroup: 8 },
    },
    NewProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    NewProductListWithBanner: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 2, slidesPerGroup: 2 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 1, slidesPerGroup: 1 },
        1200: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    NewProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    // same as NewProductList
    NewRecipeList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    RecipeListingGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    RecipeListingSwiperNarrow: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2.25, slidesPerGroup: 2.25 },
    },
    DrOffersList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 6, slidesPerGroup: 6 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    WeeklyMenuList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3 },
        900: { slidesPerView: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 3 },
        1200: { slidesPerView: 4 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 5 },
    },
    PersonalOffersList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2.25, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 5, slidesPerGroup: 5 },
    },
    TVOffersList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 3 },
        900: { slidesPerView: 2 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4 },
    },
    FooterGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
};

// I.e. My Coop pages
const DEFAULT_WITH_SIDEMENU_BREAKPOINTS: SwiperBreakpointsCollection = {
    ...FULL_WITH_SIDEMENU_BREAKPOINTS,
    ProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 4, slidesPerGroup: 4 },
        900: { slidesPerView: 6, slidesPerGroup: 6 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 4, slidesPerGroup: 4 },
        1200: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    ProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 4, slidesPerGroup: 4 },
        900: { slidesPerView: 6, slidesPerGroup: 6 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 4, slidesPerGroup: 4 },
        1200: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    NewProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    NewProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    // same as NewProductList
    NewRecipeList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    PersonalOffersList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 4 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4 },
    },
};

// I.e. Checkout, Coop start page, Butiker, Global Search results
const DEFAULT_WITHOUT_SIDEMENU_BREAKPOINTS: SwiperBreakpointsCollection = {
    ...FULL_WITH_SIDEMENU_BREAKPOINTS,
    ProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    // mostly same as ProductList
    ProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    NewProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    NewProductListWithBanner: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 2, slidesPerGroup: 2 },
        1200: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    // mostly same as NewProductList
    NewProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    // same as NewProductList
    NewRecipeList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    Recipe: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2.25, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3.25, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 4.25, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 5.25, slidesPerGroup: 5 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6.25, slidesPerGroup: 6 },
    },
    RecipeListingGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 5, slidesPerGroup: 5 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 6, slidesPerGroup: 6 },
    },
    WeeklyMenuList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3 },
        900: { slidesPerView: 3 },
        [getSwiperBreakpoint(BrowserSize.lg)]: { slidesPerView: 4 },
        1200: { slidesPerView: 4 },
        [getSwiperBreakpoint(BrowserSize.xxlg)]: { slidesPerView: 5 },
    },
    PersonalOffersList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2.25, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.md)]: { slidesPerView: 4, slidesPerGroup: 4 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 5, slidesPerGroup: 5 },
    },
};

// I.e Recipe details page
const NARROW_WITH_SIDEMENU_BREAKPOINTS: SwiperBreakpointsCollection = {
    ...FULL_WITH_SIDEMENU_BREAKPOINTS,
    ProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    ProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 3, slidesPerGroup: 3 },
        1200: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    NewProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 2, slidesPerGroup: 2 },
        1200: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    NewProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
        [SIDEBAR_HIDE_BREAKPOINT]: { slidesPerView: 2, slidesPerGroup: 2 },
        1200: { slidesPerView: 3, slidesPerGroup: 3 },
        [getSwiperBreakpoint(BrowserSize.xlg)]: { slidesPerView: 4, slidesPerGroup: 4 },
    },
};

// I.e. Article page
const NARROW_WITHOUT_SIDEMENU_BREAKPOINTS: SwiperBreakpointsCollection = {
    ...NARROW_WITH_SIDEMENU_BREAKPOINTS,
    ProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    ProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
        900: { slidesPerView: 4, slidesPerGroup: 4 },
    },
    NewProductList: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 1.5, slidesPerGroup: 1 },
        [getSwiperBreakpoint(BrowserSize.xsm)]: { slidesPerView: 2.25, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
    },
    NewProductGrid: {
        [BREAKPOINT_MIN_FROM]: { slidesPerView: 2, slidesPerGroup: 2 },
        [getSwiperBreakpoint(BrowserSize.sm)]: { slidesPerView: 3, slidesPerGroup: 3 },
    },
};

interface BreakpointRange {
    from: number;
    to: number;
    cellsInRow: number;
}

export const getGridBreakpointRanges = (breakpointArray: SwiperBreakpoints): BreakpointRange[] => {
    const breakpoints =
        breakpointArray &&
        Object.entries(breakpointArray).map((x) => {
            const [key, value] = x;
            return {
                from: parseInt(key, 10),
                perView: isNumeric(value.slidesPerView) ? value.slidesPerView : 0,
            };
        });

    if (!breakpoints?.length)
        throw new Error('Breakpoint array must contain at least one breakpoint');

    const ranges: BreakpointRange[] = [];

    for (let i = 0; i < breakpoints.length - 1; i += 1) {
        ranges.push({
            from: breakpoints[i].from,
            to: breakpoints[i + 1].from - 1,
            cellsInRow: breakpoints[i].perView,
        });
    }

    // create last breakpoint from X to 99999
    ranges.push({
        from: breakpoints[breakpoints.length - 1].from,
        to: BREAKPOINT_MAX_TO,
        cellsInRow: breakpoints[breakpoints.length - 1].perView,
    });

    return ranges;
};
