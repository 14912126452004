/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */

'use client';

import classNames from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './Notification.module.scss';

const NotificationWrapper = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<{
        noAnimation?: boolean;
        show: boolean;
        position?: 'absolute' | 'fixed';
        ariaLive?: 'assertive' | 'polite' | 'off';
        role?: string;
    }>
>(({ position = 'fixed', ...props }, ref) => {
    return (
        <CSSTransition
            nodeRef={ref}
            in={props.show}
            timeout={{
                appear: 200,
                enter: 200,
                exit: 200,
            }}
            classNames={{
                enterActive: styles['is-open'],
                enterDone: styles['is-open'],
                exitActive: styles['is-hiding'],
            }}
            appear={!props.noAnimation}
            enter={!props.noAnimation}
            exit={!props.noAnimation}
            unmountOnExit
        >
            <div role={props.role} aria-live={props.ariaLive}>
                <section
                    ref={ref}
                    className={classNames(
                        styles.Container,
                        !props.noAnimation && styles.Animated,
                        position === 'absolute' && styles.ContainerAbsolute,
                        position === 'fixed' && styles.ContainerFixed,
                    )}
                >
                    {props.children}
                </section>
            </div>
        </CSSTransition>
    );
});

export default NotificationWrapper;
