import { useQuery } from '@tanstack/react-query';

import kimProxyFlow from '../../common/flow/kimProxyFlow';
import { kimCustomerDataQueryKeys } from '../memberQueries';

export const useKimCustomerDataQuery = (enabled: boolean) => {
    const query = useQuery({
        queryKey: kimCustomerDataQueryKeys.defaultDataSet(),
        queryFn: async () => {
            return kimProxyFlow.getCustomerData();
        },
        enabled,
    });

    return query;
};
