import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

// when aria live is added first time to the DOM, itll not be read by the screen readers, hence we need to delay the first message so its picked up
const DelayedA11yAnnouncer = (props: {
    a11yMessage: string;
    name: string;
    delayFirstMessage: boolean;
}) => {
    const wasDelayed = useRef<boolean>(!props.delayFirstMessage);
    const [delayedMessage, setDelayedMessage] = useState<string>('');

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (!wasDelayed.current) {
            setTimeout(() => {
                setDelayedMessage(props.a11yMessage);
                wasDelayed.current = true;
            }, 500);
        } else {
            setDelayedMessage(props.a11yMessage);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [props.a11yMessage]);

    return (
        <div
            className="u-hiddenVisually"
            data-a11-logger={props.name} // just to show which logger it belongs too
            aria-live="polite"
        >
            {delayedMessage}
        </div>
    );
};

export default DelayedA11yAnnouncer;
