import { IconButton, ModalHeader, Search } from '@coop/components';
import { CloseIcon } from '@coop/icons';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import * as React from 'react';
import { Suspense, useImperativeHandle, useRef } from 'react';

import { BrowserSize } from '../../../../utility/browser';
import { Modal } from '../../../common/components/atoms/Modal';
import { useFromBrowserMediaQuery } from '../../../common/components/atoms/ResponsiveBreakpoints';
import { useSearchStateContext } from '../../../search/contexts/searchStateContext';
import styles from './SearchOverlay.module.less';

interface SearchOverlayProps {
    onEnterPress: () => void;
    children: ReactNode;
    onOutsideClick: () => void;
    onClearClick: () => void;
    inputPlaceholder: string;
    announcerSlot?: React.ReactNode;
    inputAriaLabel?: React.ComponentPropsWithoutRef<typeof Search>['aria-label'];
}

const SearchOverlay = React.forwardRef<
    HTMLInputElement,
    React.PropsWithChildren<SearchOverlayProps>
>((props, inputRef) => {
    const { query, isOpen, clear, setQuery, resetQuery, show } = useSearchStateContext();

    const isFromLarge = useFromBrowserMediaQuery(BrowserSize.lg);

    const searchInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(inputRef, () => searchInputRef.current as HTMLInputElement);

    return (
        <Modal
            additionalClasses={styles.SearchOverlay}
            isOpen={isOpen}
            close={clear}
            initialFocusOnDialog={false}
            animation="fade"
            afterOutsideClick={props.onOutsideClick}
        >
            <ModalHeader className="u-hiddenVisually">Sökresultat</ModalHeader>
            <div
                className={classNames(
                    styles.SearchBarWrapper,
                    show && styles['SearchBarWrapper--hasResults'],
                )}
            >
                <Search
                    ref={searchInputRef}
                    inputText={query}
                    setInputText={setQuery}
                    useDropdown={false}
                    size={isFromLarge ? 'large' : 'medium'}
                    inputPlaceholder={props.inputPlaceholder}
                    onEnterPress={props.onEnterPress}
                    onEscapePress={clear}
                    onReset={() => {
                        resetQuery();
                        searchInputRef.current?.focus();
                    }}
                    focusOnMount
                    theme="default"
                    hasOreoFocus
                    aria-label={props.inputAriaLabel}
                />
                <IconButton
                    theme="primaryOutline"
                    icon={CloseIcon}
                    label="Stäng"
                    size={40}
                    onClick={() => {
                        clear();
                        props.onClearClick();
                    }}
                />
            </div>
            {props.announcerSlot}
            <Suspense fallback={null}>
                {show && <div className={styles.SearchResults}>{props.children}</div>}
            </Suspense>
        </Modal>
    );
});
export default SearchOverlay;
