import { Button } from '@coop/components';
import classNames from 'classnames';
import React from 'react';

import styles from './SkipLink.module.scss';

const SkipButton = (props: {
    align?: 'start' | 'center';
    children: React.ReactNode;
    onClick?: () => void;
    asChild?: boolean;
}) => {
    return (
        <Button
            theme="primaryOutline"
            className={classNames(
                styles.SkipLink,
                props.align === 'center' && styles['SkipLink--centered'],
                'u-noPrint',
            )}
            size={56}
            asChild={props.asChild}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    );
};

export default SkipButton;
