import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store/structureDefinitions/cartState';

const isCartInitialized = (state: RootState) => state.cart.cartLoading.isInitialized;
const isProductDataAvailable = (state: RootState) => state.cart.cartLoading.isProductDataAvailable;
const cartId = (state: RootState) => state.cart.summary.cartId;

const selectedTimeslot = (state: RootState) =>
    (!!state.cart.timeslot &&
        !!state.cart.timeslot.isInitialized &&
        !!state.cart.timeslot.isSelected &&
        state.cart.timeslot.timeslot) ||
    null;

const hasSelectedTimeslot = (state: RootState) =>
    state.cart.timeslot &&
    state.cart.timeslot.isInitialized &&
    state.cart.timeslot.isSelected &&
    state.cart.timeslot.timeslot &&
    !!state.cart.timeslot.timeslot.id;

const isInEditOrder = (state: RootState) => isInCartEditOrder(state.cart);
const editOrderNumber = (state: RootState) => cartEditOrderNumber(state.cart);
const cartEditOrderNumber = (cart: RootState['cart']) => cart.editOrder.editOrderNumber;
const editOrderSummary = (state: RootState) => state.cart.editOrder.editOrderSummary;
const isInCartEditOrder = (state: RootState['cart']) => state.editOrder.isInEditOrderMode;

const editOrderDate = (state: RootState) =>
    (state.cart.timeslot &&
        state.cart.timeslot.isInitialized &&
        state.cart.timeslot.timeslot.startTime &&
        new Date(state.cart.timeslot.timeslot.startTime)) ||
    null;

const startTimeEditOrderTimeslotSelector = (cart: RootState['cart']) =>
    cartEditOrderTimeslot(cart)?.startTime;
const endTimeEditOrderTimeslotSelector = (cart: RootState['cart']) =>
    cartEditOrderTimeslot(cart)?.endTime;

const cartEditOrderTimeslotStartTime = createSelector(
    startTimeEditOrderTimeslotSelector,
    (startTime) => {
        return startTime ? new Date(startTime) : null;
    },
);

const cartEditOrderTimeslotEndTime = createSelector(endTimeEditOrderTimeslotSelector, (endTime) => {
    return endTime ? new Date(endTime) : null;
});

const editOrderTimeslot = (state: RootState) => cartEditOrderTimeslot(state.cart);

const cartEditOrderTimeslot = (cart: RootState['cart']) =>
    (isInCartEditOrder(cart) &&
        cart.timeslot &&
        cart.timeslot.isInitialized &&
        cart.timeslot.timeslot) ||
    null;

const editOrderExpirationTime = (state: RootState) =>
    (state.cart.editOrder?.editOrderTimeout && new Date(state.cart.editOrder.editOrderTimeout)) ||
    null;

export const cartSelectors = {
    selectedTimeslot,
    isCartInitialized,
    isProductDataAvailable,
    hasSelectedTimeslot,
    editOrderDate,
    editOrderExpirationTime,
    isInEditOrder,
    editOrderNumber,
    cartEditOrderNumber,
    editOrderSummary,
    cartId,
    editOrderTimeslot,
    cartEditOrderTimeslotStartTime,
    cartEditOrderTimeslotEndTime,
};
