/* eslint-disable react/jsx-props-no-spreading */
import type { FC } from 'react';
import * as React from 'react';

import { appConfig } from '../../../../appConfig';
import Portal from '../../../common/components/atoms/Portal';
import StickyBottom from '../../../common/components/atoms/StickyBottom';
import CncFlyIn from '../../../common/components/molecules/CncFlyIn';
import ComponentPortals from '../../../common/components/molecules/ComponentPortals';
import useCurrentStoreSync from '../../../common/hooks/useCurrentStoreSync';
import { usePageConsumptionTracking } from '../../../common/hooks/usePageConsumptionTracking';
import Consent from '../../../member/components/Consent';
import Footer from '../Footer/Footer';
import { HeaderAlertsPortal } from '../HeaderAlerts';
import ImpersonationNotice from '../ImpersonationNotice';
import { SkipLink } from '../SkipLink';
import { useInitialCartFetch, useRedirectReasonHandler } from './CommonLayout.hooks';

interface CommonLayoutProps {}

/**
 * Contains common components and hooks that are common for all other layouts like EcommerceLayout, BaseLayout
 */
const CommonLayout: FC<React.PropsWithChildren<CommonLayoutProps>> = (props) => {
    useCurrentStoreSync();
    useInitialCartFetch();
    useRedirectReasonHandler();
    usePageConsumptionTracking();

    const footer = React.useMemo(
        () =>
            structuredClone(
                appConfig.coopSettings.footer,
            ) as RecursiveRemoveReadonly<FrontendFooter>,
        [],
    );

    return (
        <>
            <Portal domNodeSelector="#skipLinkToContent">
                <SkipLink href="#mainContent">Hoppa över meny</SkipLink>
            </Portal>

            <HeaderAlertsPortal />
            {props.children}
            <Consent />
            <StickyBottom usePortal>
                <ImpersonationNotice />
            </StickyBottom>
            <CncFlyIn />

            <ComponentPortals cmpName="Footer" render={() => <Footer footer={footer} />} />
        </>
    );
};

export default CommonLayout;
