import { isBefore } from 'date-fns';

import { LocalStorageFacade } from '../../../../facades/storage/localStorageFacade';
import type { SectionTree } from '../../../ecommerce/components/SectionMenu';

interface SectionStorage {
    sessionId: string;
    shoppingUserId?: string;
    productionUnit: string;
    isCompanyUser: boolean;
    sectionTree: SectionTree;
    insertedAt: string;
}

const SECTION_CACHE_EXPIRE_TIME_MINUTES = 30;

const getEcommerceSectionsKey = (isB2bRoute: boolean) =>
    `Ecommerce:SectionsList:${isB2bRoute ? 'b2b' : 'b2c'}`;

const ecommerceSectionsLS = {
    get: (context: {
        productionUnit: string;
        isB2bRoute: boolean;
        isCompanyUser: boolean;
        shoppingUserId?: string;
    }) => {
        const sectionListStorage = LocalStorageFacade.instance.getObject<SectionStorage>(
            getEcommerceSectionsKey(context.isB2bRoute),
        );

        const now = new Date();

        if (
            !sectionListStorage ||
            !sectionListStorage.insertedAt || // previous visitors wont have that value, so cache will be invalidated for them
            isBefore(
                new Date(sectionListStorage.insertedAt),
                new Date(now.setMinutes(now.getMinutes() - SECTION_CACHE_EXPIRE_TIME_MINUTES)),
            ) // keep invalidating sections every X minutes
        )
            return undefined;

        if (
            sectionListStorage.sessionId === LocalStorageFacade.instance.getSessionId() &&
            sectionListStorage.productionUnit === context.productionUnit &&
            sectionListStorage.shoppingUserId === context.shoppingUserId &&
            sectionListStorage.isCompanyUser === context.isCompanyUser
        )
            return sectionListStorage.sectionTree;

        LocalStorageFacade.instance.remove(getEcommerceSectionsKey(context.isB2bRoute));
        return undefined;
    },
    save: (
        tree: SectionTree,
        context: {
            productionUnit: string;
            isB2bRoute: boolean;
            isCompanyUser: boolean;
            shoppingUserId?: string;
        },
    ): void => {
        const sectionTreeStorage: SectionStorage = {
            sessionId: LocalStorageFacade.instance.getSessionId(),
            shoppingUserId: context.shoppingUserId,
            productionUnit: context.productionUnit,
            isCompanyUser: context.isCompanyUser,
            sectionTree: tree,
            insertedAt: new Date().toISOString(),
        };
        LocalStorageFacade.instance.addObject<SectionStorage>(
            getEcommerceSectionsKey(context.isB2bRoute),
            sectionTreeStorage,
        );
    },
};

export default ecommerceSectionsLS;
