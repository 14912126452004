import { ChipIconButton } from '@coop/components';
import { CloseIcon } from '@coop/icons';
import * as React from 'react';

import { appConfig } from '../../../../../appConfig';
import { Modal } from '../../../../common/components/atoms/Modal';
import FormPhone from '../../../../myCoop/myProfile/components/ChangePhoneNumber/FormPhone/FormPhone';
import FormValidate from '../../../../myCoop/myProfile/components/ChangePhoneNumber/FormValidate/FormValidate';
import Success from '../../../../myCoop/myProfile/components/ChangePhoneNumber/Success/Success';
import { useKimCustomerDataQuery } from '../../../hooks/useKimCustomerDataQuery';
import memberUtils from '../../../member.utils';
import styles from './PhoneNumberPopup.module.less';

const PhoneNumberPopup: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [currentStep, setCurrentStep] = React.useState(1);
    const [openPhonePopup, setOpenPhonePopup] = React.useState(true);
    const [timeLeft, setTimeLeft] = React.useState(0);

    const { hasKimCustomerId, isMedmeraCustomer } = appConfig.coopUserSettings;

    const { data: customerData } = useKimCustomerDataQuery(hasKimCustomerId);

    const kimPhoneNumberVerified = customerData && memberUtils.kimPhoneNumberVerified(customerData);
    const kimPhoneNumber = customerData && memberUtils.kimPhoneNumber(customerData);

    if (kimPhoneNumberVerified) {
        return null;
    }

    const closePhoneNumberPopup = () => {
        setOpenPhonePopup(false);
    };

    const onSubmitSuccessPhoneForm = () => {
        setCurrentStep(2);
    };

    const onSubmitSuccessValidateForm = () => {
        if (isMedmeraCustomer) {
            // TODO: if they are a member, then add them to all sms consents here
        }

        setCurrentStep(3);
    };

    return (
        <Modal isOpen={openPhonePopup} additionalClasses={styles.PhoneNumberPopup}>
            <div className={styles.CloseButtoDiv}>
                <ChipIconButton
                    theme="whiteGreen"
                    onClick={() => setOpenPhonePopup(false)}
                    size={32}
                    icon={CloseIcon}
                    label="Stäng"
                    className={styles.CloseButton}
                />
            </div>

            <div className={styles.Content}>
                {currentStep === 1 && (
                    <FormPhone
                        headline="Mitt mobilnummer för alla goda kampanjer"
                        phoneDefaultValue={kimPhoneNumber ?? undefined}
                        onSubmitSuccess={onSubmitSuccessPhoneForm}
                        setPhoneNumber={setPhoneNumber}
                        onClose={closePhoneNumberPopup}
                        showCancelButton
                        cancelButtonText="Nej tack, inga sms till mig"
                        setTimeLeft={setTimeLeft}
                    />
                )}
                {currentStep === 2 && (
                    <FormValidate
                        phoneNumber={phoneNumber}
                        onSubmitSuccess={onSubmitSuccessValidateForm}
                        timeLeftFirstAttempt={timeLeft}
                        onClose={closePhoneNumberPopup}
                    />
                )}
                {currentStep === 3 && (
                    <Success onClose={() => closePhoneNumberPopup()} phoneNumber={phoneNumber} />
                )}
            </div>
        </Modal>
    );
};

export default PhoneNumberPopup;
