/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactNode } from 'react';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Browser, BrowserSize } from '../../../../utility/browser';

export const useBrowserMediaQuery = (size: BrowserSize) =>
    useMediaQuery({
        minWidth: Browser.getBreakpoint(size).from,
        maxWidth: Browser.getBreakpoint(size).to,
    });

export const useFromBrowserMediaQuery = (size: BrowserSize) =>
    useMediaQuery({
        minWidth: Browser.getBreakpoint(size).from,
    });

export const useBrowserCustomMediaQuery = (minWidth: number, maxWidth: number) =>
    useMediaQuery({
        minWidth,
        maxWidth,
    });

export const useBrowserMinMediaQuery = (minWidth: number) =>
    useMediaQuery({
        minWidth,
    });

export const useIsMobile = () =>
    useMediaQuery({ maxWidth: Browser.getBreakpoint(BrowserSize.sm).from });

export const useIsDesktop = () =>
    useMediaQuery({ minWidth: Browser.getBreakpoint(BrowserSize.lg).from });

export const useIsFromXXSmall = () =>
    useMediaQuery({
        minWidth: Browser.getBreakpoint(BrowserSize.xxsm).from,
    });

export const useIsFromXSmall = () =>
    useMediaQuery({
        minWidth: Browser.getBreakpoint(BrowserSize.xsm).from,
    });

export const useIsFromMedium = () =>
    useMediaQuery({ minWidth: Browser.getBreakpoint(BrowserSize.md).from });

export const useIsFromLarge = () =>
    useMediaQuery({ minWidth: Browser.getBreakpoint(BrowserSize.lg).from });

export const useIsFromXLarge = () =>
    useMediaQuery({
        minWidth: Browser.getBreakpoint(BrowserSize.xlg).from,
    });

export const Mobile = ({ children }: { children: ReactNode }) => {
    const isMobile = useIsMobile();
    return isMobile ? <>{children}</> : null;
};

export const Desktop = ({ children }: { children: ReactNode }) => {
    const isDesktop = useIsDesktop();
    return isDesktop ? <>{children}</> : null;
};

export const NotDesktop = ({ children }: { children: ReactNode }) => {
    const isDesktop = useIsDesktop();
    return isDesktop ? null : <>{children}</>;
};
