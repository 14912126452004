import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';

import type { ContentParams } from '../../api/contentDeliveryApi';
import { contentDeliveryApi } from '../../api/contentDeliveryApi';
import { epiPageQueryKeys } from '../../epiQueryKeys';
import type { EpiSitePageType } from '../pages/epiSitePageType';

export const useEpiPageByContentId = <T extends IContent>(
    contentReferenceId: string | undefined,
    params: ContentParams<T>,
    queryOptions?: Pick<Parameters<typeof useQuery<T>>[0], 'enabled' | 'staleTime' | 'gcTime'>,
) => {
    const query = useQuery({
        queryKey: epiPageQueryKeys.getByContentReference(contentReferenceId!, params),
        queryFn: () => contentDeliveryApi.getContentExtended<T>(contentReferenceId!, params),
        staleTime: 1000 * 60 * 1,
        placeholderData: keepPreviousData,
        enabled: !!contentReferenceId,
        ...queryOptions,
    });

    return query;
};

export const useEpiPageByUrl = <T extends IContent>(
    route: string,
    params: ContentParams<T> | undefined = undefined,
) => {
    const query = useQuery({
        queryKey: epiPageQueryKeys.getByUrl(route, params),
        queryFn: () => contentDeliveryApi.getContentByUrl<T>(route, params),
        staleTime: 1000 * 60 * 1,
        placeholderData: keepPreviousData,
        enabled: !!route,
    });

    return query;
};

export const useCurrentPageEpiBasePath = (allowWildCards: boolean) => {
    const location = useLocation();
    const pathParams = useParams<Record<string, string>>();

    const clientOnlySegments =
        Object.entries(pathParams)
            .filter((item) => item[0] !== '*' || allowWildCards)
            ?.map((item) => ({
                segment: item[0],
                value: item[1],
            })) || [];
    const firstClientOnlySegmentValue = clientOnlySegments[0]?.value;

    const firstClientOnlyParamIndex =
        firstClientOnlySegmentValue && location.pathname.indexOf(firstClientOnlySegmentValue);

    // strip client-side route segment, so that only the exact Epi page URL is sent to Content Delivery API
    let basePath = location.pathname;
    if (firstClientOnlyParamIndex && firstClientOnlyParamIndex > -1) {
        basePath = basePath.substring(0, firstClientOnlyParamIndex);
    }

    // if we fuck up routers uncomment this
    // const basePathTrimmed = trimEnd(basePath, '/');
    return basePath;
};

export const useCurrentEpiPage = <T extends EpiSitePageType>(
    params: ContentParams<T> = { depth: 1 },
    allowWildCards?: boolean,
) => {
    const basePath = useCurrentPageEpiBasePath(!!allowWildCards);

    const query = useQuery<T>({
        queryKey: epiPageQueryKeys.getByUrl(basePath, params),
        queryFn: () => contentDeliveryApi.getContentByUrl<T>(basePath, params),
        staleTime: 1000 * 60 * 1,
        placeholderData: keepPreviousData,
        enabled: !!basePath,
    });

    return query;
};
