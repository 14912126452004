import { getAuthToken } from '../api/auth/getAuthToken';
import { kimProxyDirectApi } from '../api/finance/kimProxyApi';

const kimProxyFlow = {
    getCustomerData: async () => {
        const identity = await getAuthToken();
        const details = await kimProxyDirectApi.getCustomerData(identity.token);
        return details;
    },
    getCustomerDataFull: async () => {
        const identity = await getAuthToken();
        const details = await kimProxyDirectApi.getCustomerDataFull(identity.token);
        return details;
    },
    deleteCustomerDataCache: async () => {
        const identity = await getAuthToken();
        await kimProxyDirectApi.deleteCustomerDataCache(identity.token);
    },
};

export default kimProxyFlow;
