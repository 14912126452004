import React from 'react';

import { useDocumentPropertyHeightSetter } from '../../../common/hooks/useDocumentPropertyHeightSetter';

const HeaderNoticesContainer = (props: { children: React.ReactNode }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    useDocumentPropertyHeightSetter('--headerNoticesContainerHeight', ref);

    return <div ref={ref}>{props.children}</div>;
};

export default HeaderNoticesContainer;
