/* eslint-disable no-console */
import trackingFlow from '../../flow/trackingFlow';
import { failSilently } from '../utils/failSilently';

const trackRecipeClick = failSilently(
    (identifier: string) => {
        trackingFlow.recipeClick({ recipeId: identifier });
    },
    () => console.error('Failed to track recipe click'),
);

const trackAddToCart = failSilently(
    (productData: ProductTrackingData[]) => trackingFlow.addToCart(productData),
    () => console.error('Failed to track addToCart'),
);

const trackAddToCartBulk = failSilently(
    (productData: ProductTrackingData[]) => {
        trackingFlow.addToCart(productData);
    },
    () => console.error('Failed to track addToCartBulk'),
);

const trackPurchase = failSilently(
    (purchaseData: PurchaseTrackingData) => {
        trackingFlow.purchase({
            orderId: purchaseData.transactionId,
            revenue: purchaseData.totalValue,
            products: purchaseData.cartItems.map((product) => ({
                productId: product.identifier,
                quantity: product.quantity,
                itemPrice: product.price / (product.quantity || 1),
            })),
        });
    },
    () => console.error('Failed to track purchase'),
);

const trackRemoveFromCart = failSilently(
    (productData: ProductTrackingData) => {
        trackingFlow.removeFromCart(productData);
    },
    () => console.error('Failed to track removeFromCart'),
);

const trackSlotClick = failSilently(
    (productId: string, slotId: string | undefined) => {
        if (slotId) {
            trackingFlow.slotClick({ productId, slotId });
        }
    },
    () => console.error('Failed to track slot click'),
);

const trackCartItemQuantityUpdate = failSilently(
    (productData: ProductTrackingData) => {
        trackingFlow.addToCart([productData]);
    },
    () => console.error('Failed to track cart item quantity update'),
);

const trackUserLogin = failSilently(
    () => {
        trackingFlow.login();
    },
    () => console.error('Failed to track user login'),
);

export const personalizationTracking = {
    trackAddToCart,
    trackAddToCartBulk,
    trackSlotClick,
    trackRemoveFromCart,
    trackCartItemQuantityUpdate,
    trackPurchase,
    trackUserLogin,
    trackRecipeClick,
};
